.pqt-geral {
  display: flex;
  flex-direction: row;
  align-self: right;
  touch-action: manipulation;
}

.pqt {
  font-size: 15px;
  width: 28px;
  text-align: center;
  align-self: center;
}

.pqt-plus,
.pqt-minus {
  text-align: center;
  background: #dadada;
  border: none;
  font-size: 15px;
  height: 25px;
  margin: 2px;
  width: 20px;
  display: inline-block;
}

.pqt-plus:hover,
.pqt-minus:hover {
  background: #53b5aa;
  color: #fff;
  cursor: pointer;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }