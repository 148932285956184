@import url("https://fonts.googleapis.com/css?family=Poppins");


.top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.top-close {
  font-size: 20px;
  border: none;
  border-radius: 5px;
  margin-right: 20px;
  padding: 5px;
  cursor: pointer;
  justify-self: start;
}

.summary-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-list {
  overflow: auto;
  height: 300px;
}

.card-item {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-around;
  max-height: 40vh;

}

.img-container {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
}

.product-img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.product_info {
  display: flex;
  padding-top: 3px;
  padding-bottom: 3px;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 300px;
  margin: 5px;
}

.product_name {
  color: #a1a1a1;
  width: 100%;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  align-self: flex-end;
  text-align: left;
}

.product-calc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 230px;
  height: 20px;
  font-size: 10px;
}

.product-total {
  font-size: 12px;
  text-align: left;
}

.order_total {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  height: 40px;
}

.finish-order {
  margin: 20px;
}