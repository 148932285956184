.site-layout-content {
  min-height: 280px;
  padding: 15px;
  background: #fff;
}

.steps-content {
  min-height: 150px;
  margin: 5px;
  padding: 10px;
  padding-top: 20px;
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}
